import { Route, Switch } from 'react-router-dom';

import LandingPage from 'pages/LandingPage';
import ProjectPage from 'pages/ProjectPage';
import ProjectDetailPage from 'pages/ProjectDetailPage';
import TeamPage from 'pages/TeamPage';
import DiscussProjectPage from 'pages/DiscussProjectPage';
import NotFoundPage from 'pages/NotFoundPage';

import 'assets/css/styles.css';
import React from 'react';
import PrivacyPolicy from 'pages/PrivacyPolicy';
import Tnc from 'pages/Tnc';
// import Tnc from 'pages/Tnc';

function App() {
  return (
    <Switch>
      <Route exact path="/" component={LandingPage} />
      <Route exact path="/project" component={ProjectPage} />
      <Route exact path="/project/:id" component={ProjectDetailPage} />
      <Route exact path="/team" component={TeamPage} />
      <Route exact path="/discuss-project" component={DiscussProjectPage} />
      <Route exact path="/privacy-policy" component={PrivacyPolicy} />
      <Route exact path="/tnc" component={Tnc} />
      <Route path="" component={NotFoundPage} />
    </Switch>
  );
}

export default App;
